import React, { useEffect, useState } from "react";

import { forms } from "../../config/forms";
import { navigate } from "gatsby";
import { downloadFileFromStorage } from "../../helpers/supabase";
import { useAuth } from "../../context/auth";
import { logDev } from "../../helpers/globalHelpers";

const FormSelector = ({ formType }) => {
  const formsConfig = forms[formType];

  const [selectedForm, setSelectedForm] = useState();

  const { userType, admin } = useAuth();

  const handleChange = (event) => {
    setSelectedForm(event.target.value);
  };

  return (
    <div class="columns is-mobile">
      <div class="column is-8-mobile is-10-tablet">
        <div class="select is-fullwidth" onChange={handleChange}>
          <select>
            <option value="">Select a form to submit</option>
            {Object.keys(formsConfig).map((form) => {
              if (
                // Admin sees all form types
                // If userType is not specified, show to all
                // Otherwise, only show to the user if their type matches
                admin ||
                typeof formsConfig[form].userType === "undefined" ||
                formsConfig[form].userType === userType
              ) {
                return <option value={form}>{form}</option>;
              }
            })}
          </select>
        </div>
      </div>
      <div class="column is-4-mobile is-2-tablet">
        <button
          class="button is-fullwidth fpLoginButton"
          onClick={() => {
            if (typeof selectedForm !== "undefined") {
              const formConfig = formsConfig[selectedForm];
              if (formConfig.type === "download") {
                downloadFileFromStorage(
                  "forms",
                  formConfig.supabaseFolder,
                  formConfig.supabaseFile
                );
              } else {
                const formId = formConfig.inHouseFormConfig.formId;
                navigate(`/submitForm?formType=${formType}&formId=${formId}`);
              }
            } else {
              alert("Select a form!");
            }
          }}
        >
          Select form
        </button>
      </div>
    </div>
  );
};

export default FormSelector;
