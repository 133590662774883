import React, { useEffect, useState } from "react";

import "../styles/styles.scss";

import Layout from "../components/layout";
import FormSelector from "../components/FormSelector";
import SubmissionTable from "../components/SubmissionTable";
import { getTitle } from "../helpers/globalHelpers";

const SelectForm = () => {
  const [formType, setFormType] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setFormType(urlParams.get("formType"));
  });

  return (
    <Layout pageName={getTitle(formType)}>
      <p class="title">{getTitle(formType)}</p>
      <div class="fpAuthenticatedContentItem">
        <FormSelector formType={formType} />
      </div>
      <div class="fpAuthenticatedContentItem">
        <SubmissionTable formType={formType} />
      </div>
    </Layout>
  );
};

export default SelectForm;
