import { inHouseForms } from "./inHouseForms";

export const forms = {
  complianceForms: {
    // "Demo compliance (in-house) ": {
    //   name: "Demo compliance form",
    //   type: "inHouse",
    //   inHouseFormConfig: inHouseForms.complianceDemoForm,
    // },
    "New Associated Person": {
      name: "New Associated Person",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.newAssociatedPerson,
    },
    "Outside Business Activity": {
      name: "Outside Business Activity",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.outsideBusinessActivity,
    },
    "Private Securities Transaction": {
      name: "Private Securities Transaction",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.privateSecuritiesTransaction,
    },
    "Pre Registration Screening": {
      name: "Pre Registration Screening",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.preRegistrationScreening,
    },
    "Beneficiary Banking Information": {
      name: "Beneficiary Banking Information",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.beneficiaryBankingInformation,
    },
    "Outside Brokerage Account": {
      name: "Outside Brokerage Account",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.outsideBrokerageAccount,
    },
    "Political Contributions": {
      name: "Political Contributions",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.politicalContributions,
    },
    "Gifts and Gratuities": {
      name: "Gifts and Gratuities",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.gifts,
    },
    "Cyber Security Questionaire": {
      name: "Cyber Security Questionaire",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.cyberSecurity,
    },
  },
  customerInformationForms: {
    "Form CRS Approval": {
      name: "Form CRS Approval",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.formCRS,
      userType: "regular",
    },
    "Client Information Form": {
      name: "Client Information Form",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.clientInformationForm,
      userType: "regular",
    },
    "Client Information Form (M&A)": {
      name: "Client Information Form (M&A)",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.clientInformationFormMA,
      userType: "m&a",
    },
    "Investor Information Form": {
      name: "Investor Information Form",
      type: "inHouse",
      inHouseFormConfig: inHouseForms.investorInformationForm,
    },
    "Investor Information Form (Self-Complete)": {
      name: "Investor Information Form (Self-Complete)",
      type: "download",
      supabaseFolder: "customer_information_forms",
      supabaseFile: "Investor Information Form (Self-Complete).pdf",
      userType: "regular",
    },
  },
};
